<template>
<div>

  <breadcrumb-base :breadcrumb="breadcrumb" title="GiftCards">
    <template v-slot:right-end>
    </template>
  </breadcrumb-base>
  <div class="content-center">
    <div class="vx-row w-full mb-base c-center flex items-center justify-around">
      <div class="md:w-2/5 w-full flex">
        <vx-card :title="$t('AddGiftCardBatch')">
          <div class="vx-row">
            <div class="vx-col w-full mt-10 mb-6">
              <div class="vx-col">
                <dynamic-gift-cards-drop-down
                  :selected-item="selectedProduct"
                  :placeholder="$t('SearchProduct')"
                  @on-item-selected="onProductChanged($event)">
                </dynamic-gift-cards-drop-down>
              </div>
              <div class="text-width vx-col py-3">
                <vs-select
                  autocomplete
                  class="w-full"
                  :label="$t('DenominationAmount')"
                  v-model="selectedProductConfig"
                >
                  <vs-select-item :key="index" :value="product" :text="product.value | germanNumberFormat"
                                  v-for="(product,index) in selectedProduct.configurations"/>
                </vs-select>
              </div>
              <div class="vx-col py-3">
                <dynamic-customers-drop-down
                  :only-seller="true"
                  :selected-item="selectedCustomer"
                  :placeholder="$t('SearchCustomer')"
                  @on-item-selected="onCustomerChanged($event)">
                </dynamic-customers-drop-down>
              </div>

              <div class="vx-col py-3 text-width">
                <div class="flex" style="justify-content: space-between;">
                  <h3>{{ $t('Batch') }}</h3>
<!--                  <vs-button radius color="danger" type="gradient" @click="batchFields += 1" icon="add"></vs-button>-->
                </div>
                <div class="pt-3 flex vs-row" v-for="index in batchFields">
                  <vs-input v-validate="'required'"
                            validate-on="change"
                            class="w-full mb-3"
                            autofocus
                            :placeholder="$t('BatchNumber') + '*'"
                            v-model="batchNumbers[index]" />
                </div>
                <vs-button @click="batchFields += 5"
                           class="pt-3" color="primary"
                           type="border"
                           icon="add">{{ $t('AddBatch') }}</vs-button>
              </div>
            </div>
          </div>
          <div class="vx-row ml-2 flex justify-center">
            <vs-button :disabled="!validateForm" class="" @click="addGiftCardBatches">{{ $t('Add') }}</vs-button>
            <div class="p-3"></div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import DynamicGiftCardsDropDown from '../products/DynamicGiftCardsDropDown'


export default {
  name: 'AddGiftCard',
  components: { DynamicCustomersDropDown, DynamicGiftCardsDropDown },
  data() {
    return {
      errorFetching: false,
      selectedProductConfig: {},
      selectedProduct: {},
      selectedCustomer: {},
      batchNumbers: [],
      batchFields: 1,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'GiftCards', i18n: 'GiftCards', url: '/admin/giftcards' },
        { title: 'AddGiftCardBatch', i18n: 'AddGiftCardBatch', active: true },
      ],
      currentPage: 0,
    }
  },
  computed: {
    validateForm() {
      return Object.keys(this.selectedProductConfig).length > 0 && Object.keys(this.selectedCustomer).length > 0 && this.batchNumbers.length > 0
    },
  },
  methods: {
    onProductChanged(product) {
      this.selectedProduct = product
    },
    onCustomerChanged(customer) {
      this.selectedCustomer = customer
    },
    resetData() {
      this.selectedProduct = {}
      this.selectedCustomer = {}
      this.selectedProductConfig = ''
      this.batchFields = 1
      this.batchNumbers = []
    },
    buildPayload() {
      const payload = {}
      if (Object.keys(this.selectedProduct).length > 0) {
        payload.product_id = this.selectedProduct.product_id
        if (Object.keys(this.selectedProductConfig).length > 0) {
          payload.product_configuration_id = this.selectedProductConfig.product_configuration_id
        }
      }
      if (Object.keys(this.selectedCustomer).length > 0) {
        payload.customer_id = this.selectedCustomer.customer_id
      }
      const j = []
      this.batchNumbers.forEach((n) => {
        if (n && n.trim()) {
          j.push(n)
        }
      })
      payload.batch_no_list = j.join(',')
      return payload
    },
    addGiftCardBatches() {
      const p = this.buildPayload()

      return this.$store.dispatch('adminGiftCards/addGiftCard', { payload: p })
        .then((data) => {
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: data.message,
              text: `${data.success} Success`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
            })
            if (data.failed > 0) {
              this.$vs.notify({
                time: 8000,
                position: 'top-center',
                title: `${data.failed} Failed`,
                text: `Pins - ${data.failed > 0 ? data.failed_batch_no_list.join(', ') : ''}`,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
              })
            }
            this.resetData()
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: `${data.message}`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'daner',
            })
          }
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
}
</script>

<style scoped>
.text-width{
  position: relative;
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin: 0 auto;
}
</style>
